<template>
    <div class="right-side">
        <h2>{{$t("AccountPage.AccountOrders.title")}}</h2>
        <div class="mb-4">{{$t("AccountPage.AccountOrders.description")}}</div>
        <table class="table table-borderless table-hover">
            <thead>
                <tr>
                    <th>{{$t("orders.order_num")}}</th>
                    <th>{{$t("orders.date")}}</th>
                    <th>{{$t("orders.tariff")}}</th>
                    <th>{{$t("orders.amount")}}</th>
                    <th>Оплачено</th>
                    <th>{{$t("orders.bill")}}</th>
                    <th>{{$t("orders.status")}}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="order in myData" :key="order.id">
                    <td>{{order.orderNum}}</td>
                    <td>{{order.entryDate}}</td>
                    <td>{{getName(order.tariffId)}}</td>
                    <td>
                        {{order.amount}} 
                        <!-- <span ng-show="discount && discount.percentage" class="red">(- {{ discount.percentage }}%)</span> -->
                    </td>
                    <td>{{order.paidAmount}}</td>
                    <td>
                        <button class="btn btn-success" :title="$t('orders.form')" @click="getInvoice(order)"><i class="fas fa-receipt"></i></button>
                        <button class="btn btn-info" :title="$t('orders.online')" @click="onlinePayment(order)"><i class="fas fa-credit-card"></i></button>
                    </td>
                    <td>{{getName(order.dorderStatusId)}}</td>
                </tr>
            </tbody>
        </table>
        <paginate
            v-model="currentPage"
            :page-count="pageCount"
            :click-handler="changePage"
            :prev-text="'&laquo;'"
            :next-text="'&raquo;'"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :page-link-class="'page-link'"
            :prev-class="'page-item'"
            :prev-link-class="'page-link'"
            :next-class="'page-item'"
            :next-link-class="'page-link'"
            >
        </paginate>

        
        <form name="paymentForm" id="paymentForm"
              method="post"
              action="https://epay.kkb.kz/jsp/process/logon.jsp"
              target="_blank" >
            <input type="hidden" id="B64Sign" name="Signed_Order_B64" v-model="paymentDetails.sign">
            <input type="hidden" name="email" v-model="paymentDetails.email">
            <input type="hidden" name="BackLink" v-model="paymentDetails.backLink">
            <input type="hidden" name="FailureBackLink" v-model="paymentDetails.failureBackLink">
            <input type="hidden" name="PostLink" v-model="paymentDetails.postLink">
        </form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            page: 0,
            totalPages: 0,
            myData: [],
            paymentDetails: {
                language: 'rus',
                sign: '',
                email: '',
                backLink: 'https://eoz.kz',
                failureBackLink: 'https://eoz.kz',
                postLink: 'https://eoz.kz/api/plan'
            },
            discount: null
        }
    },
    mounted() {
        this.onShow();
    },
    methods: {
        onShow() {
            this.getOrders();
            this.getDiscount();
        },
        getOrders() {
            this.$store.dispatch("LOAD_ORDERS", {page: this.page})
            .then(result => {
                if (result.status == 200) {
                    this.myData = result.data.content;
                    this.totalPages = result.data.totalPages;
                }
            });
        },
        getDiscount() {
            this.$store.dispatch("LOAD_DISCOUNT")
            .then(result => result.status == 200 && (this.discount = result.data));
        },
        changePage(num) {
            this.currentPage = num;
            this.getOrders();
        },
        getName(el, showDescription = false) {
            let name;
            switch(this.$i18n.locale) {
                case 'kz':
                    name = el.nameKz + (showDescription && el.detailsKz ? '; ' + el.detailsKz : '');
                    break;
                case 'ru':
                default:
                    name = el.nameRu + (showDescription && el.detailsRu ? '; ' + el.detailsRu : '');
            }
            return name;
        },
        getOrgName(userDetail) {
            var orgName = '';
            var legalEntities = ["", "АО", "ТОО"];
            
            if (userDetail.orgFullNameRu) {
                orgName = userDetail.legalEntityType > 0 ? 
                    legalEntities[userDetail.legalEntityType] + " \"" : "";
                orgName += userDetail.orgFullNameRu;
                orgName += userDetail.legalEntityType > 0 ? "\"" : "";
            }
            else if (userDetail.orgNameRu) {
                orgName = userDetail.orgNameRu;
            }
            
            return orgName;
        },
        calcDiscount(amount) {
            if (this.discount && this.discount.percentage) {
                amount -= amount * this.discount.percentage / 100;
            }
            return amount;
        },
        digtotext(dig) {
            this.words = {
                m3: [['тысяча', 'тысячи', 'тысяч'], ['миллион', 'миллиона', 'миллионов'], ['миллиард', 'миллиарда', 'миллиардов']],
                m2: ['сто', 'двести', 'триста', 'четыреста', 'пятьсот', 'шестьсот', 'семьсот', 'восемьсот', 'девятьсот'],
                m1: ['дестять', 'двадцать', 'тридцать', 'сорок', 'пятьдесят', 'шестьдесят', 'семьдесят', 'восемдесят', 'девяносто'],
                m0: ['один', 'два', 'три', 'четыре', 'пять', 'шесть', 'семь', 'восемь', 'девять', 'десять'],
                f0: ['одина', 'две'],
                l0: ['дестять', 'одиннадцать', 'двенадцать', 'тринадцать', 'четырнадцать', 'пятнадцать', 'шестнадцать', 'семнадцать', 'восемнадцать', 'девятнадцать']
            };
            this.dim = function (dig, power, words) {
                var result = '';
                var pow = Math.floor(dig / Math.pow(10, power)) % Math.pow(10, 3);
                if (!pow)
                    return result;
                var n2 = Math.floor(pow / 100);
                var n1 = Math.floor(pow % Math.pow(10, 2) / 10);
                var n0 = Math.floor(pow % 10);
                var s1 = (n1 > 0) ? ' ' : '';
                var s0 = (n0 > 0) ? ' ' : '';
                var get_n = function () {
                    switch (power) {
                        case 0:
                        case 6:
                        case 9:
                            result += s0 + words.m0[n0 - 1];
                            break;
                        case 3:
                            if (n0 < 3) {
                                result += s0 + words.f0[n0 - 1];
                            } else {
                                result += s0 + words.m0[n0 - 1];
                            }
                            break;
                    }
                };
                if (n2 > 0) {
                    result += words.m2[n2 - 1];
                }
                if (n1 > 0) {
                    if (n1 > 1) {
                        result += s1 + words.m1[n1 - 1];
                        if (n0 > 0)
                            get_n();
                    } else {
                        result += s1 + words.l0[n0];
                    }
                } else {
                    if (n0 > 0)
                        get_n();
                }
                if (power) {
                    var d = (power - 3) / 3;
                    if ((d == 0) && (n0 + n1 * 10 >= 11 && n0 + n1 * 10 <= 14)) {
                        result += ' ' + words.m3[0][2];
                    } else if (n0 == 1) {
                        result += ' ' + words.m3[d][0];
                    } else if ((n0 >= 2) && (n0 <= 4)) {
                        result += ' ' + words.m3[d][1];
                    } else if ((n0 == 0) || (n0 >= 5 && n0 <= 9)) {
                        result += ' ' + words.m3[d][2];
                    }
                }
                return result;
            }
            this.result = '';
            for (var i = 9; i > -1; i -= 3) {
                this.result += this.dim(dig, i, this.words) + ' ';
            }
            return this.result.replace(/[\s]{2,}/ig, ' ').trim();
        },

        getInvoice(order) {
            console.log('get invoice ' + order.id);
            const orgName = this.getOrgName(order.userId.userDetail);
            const orderAmount = this.calcDiscount(order.amount);
            
            const request = {
                "id": "F4ADAD56-3E39-431C-993E-4627AA6AC253",
                "params": [
                    {
                        "field": "P_BILL_NUM",
                        "value": order.orderNum
                    },
                    {
                        "field": "P_BILL_DATE",
                        "value": order.entryDate
                    },
                    {
                        "field": "P_ORGANIZATION",
                        "value": orgName
                    },
                    {
                        "field": "P_BIN",
                        "value":  order.userId.userDetail.iin
                    },
                    {
                        "field": "P_ADDRESS",
                        "value": order.userId.userDetail.legalAddress
                    },
                    {
                        "field": "P_CONTACT_PERSON",
                        "value": order.userId.userDetail.surname + ' ' + order.userId.userDetail.firstname + ' ' + (order.userId.userDetail.patronname ? order.userId.userDetail.patronname : '')
                    },
                    {
                        "field": "P_PHONE",
                        "value": order.userId.userDetail.orgPhoneNumber
                    },
                    {
                        "field": "P_BILL_SUM",
                        "value": orderAmount
                    },
                    {
                        "field": "P_BILL_SUM_TEXT",
                        "value": orderAmount + " (" + this.digtotext(orderAmount) + ") KZT"
                    },
                    {
                        "field": "P_ORDER_NUM",
                        "value": order.orderNum
                    },
                    {
                        "field": "P_TARIFF",
                        "value": order.tariffId.nameRu
                    },
                    {
                        "field": "P_BILL_SUM_NDS",
                        "value": order.amountNds
                    }
                ],
                "filename": "payment_bill.pdf"
            };
            window.open("https://eoz.kz/api/uicommand/download/report?requestParam=" + encodeURI(JSON.stringify(request)));
        },
        onlinePayment(order) {
            this.$store.dispatch("LOAD_ORDER_SIGN", order.id)
            .then(result => {
                if (result.status == 200) {
                    this.paymentDetails.sign = result.data.sign;
                    setTimeout(function() {
                        document.getElementById('paymentForm').submit();
                    }, 1000);
                }
            })
            ;
        }
    },
    computed: {
        currentPage: {
            get() {
                return this.page + 1;
            },
            set(val) {
                this.page = val - 1;
            }
        },
        pageCount() {
            return this.totalPages;
        }
    }
}
</script>